import React , {useLayoutEffect} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ManualPageTemplate extends React.Component {
  render() {
    const data = this.props.data
    const page = data.markdownRemark
    const { previous, next } = this.props.pageContext
    const language = page.frontmatter.language
    const tableOfContents = page.tableOfContents
    const therIsTableOfContents = page.frontmatter.tableOFContent

    let title = '';
    let description = '';

    if (data) {
      ({title, description} = data.site.siteMetadata[language] || data.siteMetadata.es)
    }

    return (
      <Layout
      location={this.props.location}
      title={title}
      description={description}
      language={language}
      tableOfContents ={tableOfContents}
      therIsTableOfContents = {therIsTableOfContents}
     >
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.description || page.excerpt}
        />

          <div dangerouslySetInnerHTML={{ __html: page.html }}></div>
      </Layout>
    )
  }
}

export default ManualPageTemplate

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        en {
          title
          description
        }
        es {
          title
          description
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        description
        language
        tableOFContent
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            title
            description
            language
          }
        }
      }
    }
  }
`
